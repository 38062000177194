<template>
    <b-container fluid>
        <errors-bag-list/>

        <b-card no-body class="mb-2 p-1">
            <div class="row">
                <b-col cols="12" class="text-right">
                    <b-button variant="primary" @click="applyFilters" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">
                        <b-icon-filter/>
                        Фильтры
                    </b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(Сбросить)</b-link>
                    </small>
                </b-col>
            </div>
            <b-collapse id="filters" class="mt-2 text-center">
                <b-row>
                    <b-col md="3">
                        <b-form-group description="Номенклатура">
                            <nomenclature-input v-model="filters.nomenclature"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="3">
                        <b-form-group description="Дата от">
                            <b-form-datepicker
                                id="date-from"
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                :start-weekday="1"
                                locale="ru"
                                placeholder=""
                                v-model="filters.date_from"
                                :max="filters.date_to"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group description="Дата до">
                            <b-form-datepicker
                                id="date-to"
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                :start-weekday="1"
                                locale="ru"
                                placeholder=""
                                v-model="filters.date_to"
                                :min="filters.date_from"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group description="Статус">
                            <b-form-select id="status" v-model="filters.status" :options="statusOptions"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-collapse>
        </b-card>

        <b-table
            responsive
            :items="items"
            :fields="fields"
            head-variant="dark"
        >
            <template #cell(actions)="row">
                <b-button
                    v-if="row.item.status === STATUS_RETURN_APPROVED"
                    @click="retrive(row.item)"
                    variant="primary"
                    size="sm"
                >
                    Возврат
                </b-button>
                <b-button
                    v-if="row.item.status === STATUS_WRITE_OFF_APPROVED"
                    @click="writeOff(row.item)"
                    variant="warning"
                    size="sm"
                >
                    Списать
                </b-button>
                <b-button
                    v-if="row.item.status === STATUS_WRITTEN_OFF && row.item.materialTransferAct"
                    variant="success"
                    size="sm"
                    @click="toMaterialTransferAct(row.item)"
                >
                    Акт перемещения
                </b-button>
                <b-button
                    v-if="row.item.status === STATUS_RETURNED"
                    @click="downloadWaybill(row.item)"
                    variant="success"
                    size="sm"
                    title="Накладная (в формате PDF)"
                >
                    <b-icon-printer-fill/>
                </b-button>
            </template>
        </b-table>

        <b-row>
            <b-col md="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                    из {{ meta.total_rows }} записей
                </span>
            </b-col>
            <b-col md="6">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="applyFilters"
                    class="justify-content-end"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import moment                     from 'moment';
import ErrorsBagList              from '@components/_common/ErrorsBagList';
import ErrorsBag                  from '@utils/errorsBag';
import {CORRECTION_ACTS_ENDPOINT} from '@utils/endpoints';
import NomenclatureInput          from '@components/_common/NomenclatureInput';
import lsfMixin                   from '@utils/localStorageFilterMixin';
import Downloader                 from '@utils/Downloader';

const STATUS_DRAFT = 'draft';
const STATUS_RETURN = 'return';
const STATUS_RETURN_APPROVED = 'return_approved';
const STATUS_RETURNED = 'returned';
const STATUS_WRITE_OFF = 'write_off';
const STATUS_WRITE_OFF_APPROVED = 'write_off_approved';
const STATUS_WRITTEN_OFF = 'written_off';

export default {
    name: 'Index',
    components: {
        ErrorsBagList,
        NomenclatureInput,
    },
    data() {
        return {
            ErrorsBag,
            items: [],
            fields: [
                {
                    key: 'nomenclature.name',
                    label: 'Номенклатура',
                },
                {
                    key: 'date',
                    label: 'Дата',
                    formatter(value) {
                        return value ? moment(value).format('DD.MM.YYYY') : '';
                    }
                },
                {
                    key: 'nomenclatureLot.name',
                    label: 'Партия',
                },
                {
                    key: 'author.fullname',
                    label: 'Кем создан',
                },
                {
                    key: 'contractor.name',
                    label: 'Поставщик',
                },
                {
                    key: 'count',
                    label: 'Количество',
                    formatter(value, key, item) {
                        return item.nomenclature && item.nomenclature.measureUnit
                            ? `${value} ${item.nomenclature.measureUnit.name}`
                            : value;
                    }
                },
                {
                    key: 'status',
                    label: 'Статус',
                    formatter(value) {
                        let status = '';
                        switch (value) {
                            case STATUS_DRAFT:
                                status = 'Ожидает подтверждения';
                                break;
                            case STATUS_RETURN_APPROVED:
                                status = 'Возврат одобрен';
                                break;
                            case STATUS_RETURNED:
                                status = 'Возвращено';
                                break;
                            case STATUS_WRITE_OFF_APPROVED:
                                status = 'Списание одобрено';
                                break;
                            case STATUS_WRITTEN_OFF:
                                status = 'Списано';
                                break;
                        }

                        return status;
                    }
                },
                {
                    key: 'actions',
                    label: 'Действия'
                }
            ],

            filters: {
                date_from: null,
                date_to: null,
                nomenclature: null,
                status: null,
            },

            statusOptions: [
                {
                    value: STATUS_DRAFT,
                    text: 'Ожидает подтверждения',
                },
                {
                    value: STATUS_RETURN_APPROVED,
                    text: 'Возврат одобрен',
                },
                {
                    value: STATUS_RETURNED,
                    text: 'Возвращено',
                },
                {
                    value: STATUS_WRITE_OFF_APPROVED,
                    text: 'Списание одобрено',
                },
                {
                    value: STATUS_WRITTEN_OFF,
                    text: 'Списано',
                },
            ],

            meta: {
                current_page: 1,
                total_rows: 0,
                per_page: 25,
            },

            STATUS_WRITE_OFF_APPROVED,
            STATUS_RETURN_APPROVED,
            STATUS_WRITTEN_OFF,
            STATUS_RETURNED,
        };
    },
    mixins: [lsfMixin],
    watch: {
        filters: {
            deep: true,
            handler(value) {
                this.watchFilters(value);
            },
        },
    },
    methods: {
        toMaterialTransferAct(item) {
            this.$router.push({
                name: 'MaterialTransferActUpdate',
                params: {
                    id: item.materialTransferAct.id,
                }
            });
        },
        applyFilters() {
            ErrorsBag.discard();

            this.items = [];
            let params = {
                page: this.meta.current_page,
                with: [
                    'nomenclature.measureUnit',
                    'nomenclatureLot',
                    'contractor',
                    'author',
                    'materialTransferAct',
                ],
                filters: {
                    date_from: this.filters.date_from || null,
                    date_to: this.filters.date_to || null,
                    nomenclature_id: this.filters.nomenclature ? this.filters.nomenclature.id : null,
                    status: this.filters.status || null,
                },
            };

            this.$http
                .get(CORRECTION_ACTS_ENDPOINT, {params})
                .then(response => {
                    this.items = response.data.data.data;

                    this.meta.current_page = response.data.data.current_page;
                    this.meta.per_page = response.data.data.per_page;
                    this.meta.total_rows = response.data.data.total;
                });
        },
        retrive(item) {
            ErrorsBag.discard();

            this.$bvModal.msgBoxConfirm('Вы уверены, что хотите сделать возврат?', {
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Да',
                cancelTitle: 'Нет',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then((value) => {
                if (value === true) {
                    this.$http
                        .put(`${CORRECTION_ACTS_ENDPOINT}/${item.id}`, {status: STATUS_RETURN})
                        .then(this.applyFilters);
                }
            });
        },
        writeOff(item) {
            this.$router.push({
                name: 'MaterialTransferActCreate',
                params: {
                    transfer: {
                        placeId: item.id,
                        place: CORRECTION_ACTS_ENDPOINT,
                        storageTo: item.storage_to,
                        storage: item.storage_from,
                        transmissionItems: [
                            {
                                count: item.count,
                                nomenclature: item.nomenclature,
                                nomenclatureLot: item.nomenclatureLot,
                            }
                        ],
                    },
                }
            });
        },
        downloadWaybill(act) {
            ErrorsBag.discard();

            this.$http
                .get(`${CORRECTION_ACTS_ENDPOINT}/${act.id}/waybill`, {responseType: 'arraybuffer'})
                .then((response) => {
                    Downloader.download(
                        response.data,
                        'application/pdf',
                        `Накладная от ${moment(act.created_at).format('DD.MM.YYYY')} на возврат.pdf`);
                })
                .catch((response) => {
                    // Парсим arraybuffer в строку
                    const textDecoder = new TextDecoder('UTF-8');
                    const error = textDecoder.decode(response.data);

                    ErrorsBag.add(error)
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                });
        },
    },
    mounted() {
        this.initFilters(this, this.filters);
    }
}
</script>
